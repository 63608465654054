import React from 'react';
import { PropTypes } from 'prop-types';

const Solutions = ({ data, image }) => (
  <section className="container--md">
    <div id="solutions" className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 padding--md" style={{ alignItems: 'center', display: 'flex' }}>
        <div dangerouslySetInnerHTML={{ __html: data[0].content[0].value }} />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 hidden--xs hidden--sm">
        <img
          src={image}
          alt="Upendo is a South Florida Staffing Agency providing you the following solutions"
          pexel="https://www.pexels.com/photo/three-person-sitting-on-chair-near-table-inside-room-3183134/"
          className="responsive"
        />
      </div>
    </div>
  </section>
);

Solutions.propTypes = {
  data: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
};

export default Solutions;
