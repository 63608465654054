import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import About from '../components/about';
import Hero from '../components/hero';
import Solutions from '../components/solutions';

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query IndexQuery {
        allContentfulHomepage {
          edges {
            node {
              pageTitle

              heroImage {
                file {
                  url
                }
              }

              heroSection {
                content {
                  content {
                    value
                  }
                }
              }

              aboutImage {
                file {
                  url
                }
              }

              aboutSection {
                content {
                  content {
                    value
                  }
                }
              }

              solutionsImage {
                file {
                  url
                }
              }

              solutionsSection {
                content {
                  content {
                    value
                  }
                }
              }

              meta {
                meta {
                  content
                  name
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const graphqlData = data.allContentfulHomepage.edges[0].node;

      return (
        <Layout>
          <SEO
            title={graphqlData.pageTitle}
            lang="en"
            meta={graphqlData.meta}
          />

          <section id="homepage">
            <Hero
              image={graphqlData.heroImage.file.url}
              data={graphqlData.heroSection.content}
            />

            <About
              data={graphqlData.aboutSection.content}
              image={graphqlData.aboutImage.file.url}
            />

            <br />

            <Solutions
              data={graphqlData.solutionsSection.content}
              image={graphqlData.solutionsImage.file.url}
            />
          </section>
        </Layout>
      );
    }}
  />
);

export default IndexPage;
