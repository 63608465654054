import React from 'react';
import { PropTypes } from 'prop-types';

const About = ({ data, image }) => (
  <section className="margin--md">
    <div id="about" className="row">
      <div className="col-6 hidden--xs hidden--sm">
        <img
          src={image}
          alt="We can help you find jobs in Miami, see who's hiring in West Palm beach, and find jobs in South Florida"
          pexel="https://www.pexels.com/photo/three-person-sitting-on-chair-near-table-inside-room-3183134/"
          className="responsive"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 padding--md" style={{ alignItems: 'center', display: 'flex' }}>
        <div dangerouslySetInnerHTML={{ __html: data[0].content[0].value }} />
      </div>
      {/* moving the image below for mobile */}
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 hidden--md hidden--lg">
        <img
          src={image}
          alt="We can help you find jobs in Miami, see who's hiring in West Palm beach, and find jobs in South Florida"
          pexel="https://www.pexels.com/photo/three-person-sitting-on-chair-near-table-inside-room-3183134/"
          className="responsive"
        />
      </div>
    </div>
  </section>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
};

export default About;
