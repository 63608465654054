import React from 'react';
import { PropTypes } from 'prop-types';

const Hero = ({ data, image }) => (
  <div
    id="hero"
    className="padding--lg row"
    style={{
      background: `url(${image}) no-repeat center -350px fixed`,
      backgroundSize: 'cover',
      alignItems: 'center',
      display: 'flex',
      marginBottom: 25,
    }}
  >
    <div
      dangerouslySetInnerHTML={{ __html: data[0].content[0].value }}
      className="text--center col-12 col-sm-12 col-md-12 col-lg-12"
      style={{ width: '100%' }}
    />
  </div>
);

Hero.propTypes = {
  data: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
};

export default Hero;
